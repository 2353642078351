
import { seoHead } from '~/assets/seo'

export default {
  name: 'PageSlug',
  nuxtI18n: {
    paths: {
      nl: '/*',
      en: '/*',
    },
  },
  async asyncData({ route, store, redirect, error, i18n }) {
    const uri = route.params.pathMatch
    const [, page] = await Promise.all([store.dispatch('navigation/fetch'), await store.dispatch('page/fetchUri', { uri, push: true })])

    if (!page) {
      const seoRedirect = await store.dispatch('seo/fetch', { path: route.fullPath })
      if (seoRedirect) {
        return redirect(seoRedirect.type, seoRedirect.to)
      }
      return error({ statusCode: 404 })
    }

    // Some pages require additional fetches to be performed
    const overviewType = page.section
    switch (overviewType) {
      case 'genericSearchPage':
        store.commit('generic-search/initialize', route.query)
        await store.dispatch('generic-search/fetch')
        break

      case 'eventOverview':
      case 'newsOverview':
      case 'newsArchiveOverview':
      case 'personOverview':
      case 'productOverview':
      case 'projectOverview':
      case 'publicationOverview':
      case 'storyOverview':
        // eslint-disable-next-line no-case-declarations
        const overviewParams = { ...route.query, overviewType }
        if (overviewType === 'publicationOverview' && i18n.locale === 'en' && overviewParams.languages === undefined) {
          overviewParams.languages = ['English']
        }
        store.commit('overview/initialize', overviewParams)
        await Promise.all([store.dispatch('overview/fetchAllFacets'), store.dispatch('overview/fetch')])
        break
    }

    return { page }
  },
  data() {
    return {
      componentMap: {
        eventPage: 'EventPage',
        genericPage: 'GenericPage',
        genericSearchPage: 'GenericSearchPage',
        newsPage: 'NewsPage',
        overviewPage: 'OverviewPage',
        personPage: 'PersonPage',
        productPage: 'ProductPage',
        projectPage: 'ProjectPage',
        publicationPage: 'PublicationPage',
        storyPage: 'StoryPage',

        // Most overview pages are handled by the default overview template
        eventOverview: 'Overview',
        personOverview: 'Overview',
        productOverview: 'Overview',
        projectOverview: 'Overview',
        publicationOverview: 'Overview',

        // News and story overview pages are handled by the same "wide" template
        newsOverview: 'OverviewWide',
        newsArchiveOverview: 'OverviewWide',
        storyOverview: 'OverviewWide',
      },
    }
  },
  head() {
    return seoHead(this.page)
  },
}
